import jwt_decode from 'jwt-decode';


class Auth {
  constructor() {
  }

  static checkAuth() {
    let currentDateTime = new Date();
    const israelCurrentTimeString = currentDateTime.toLocaleString('en-US', { timeZone: 'Asia/Jerusalem' });
    const israelCurrentTime = new Date(israelCurrentTimeString);

    let tokenExpireString = this.checkTokenDate();

    if (!tokenExpireString) {
      return false;
    }

    let tokenExpireDate = new Date(tokenExpireString);

    if (israelCurrentTime > tokenExpireDate) {
      // Token has expired
      return false;
    } else {
      return true;
    }
  }

  static checkTokenDate() {
    const tokenFromStorage = localStorage.getItem('token');
    if (tokenFromStorage) {
      let decodedToken = jwt_decode(tokenFromStorage);
      return decodedToken['_expiresIn'];
    } else {
      return false;
    }

  }

 static getUserRole(){
  const tokenFromStorage = localStorage.getItem('token');
  if (tokenFromStorage) {
    let decodedToken = jwt_decode(tokenFromStorage);
    return decodedToken['_role'];
  } else {
    return false;
  }
 }

 static getUserId(){
  const tokenFromStorage = localStorage.getItem('token');
  if (tokenFromStorage) {
    let decodedToken = jwt_decode(tokenFromStorage);
    return decodedToken['_id'];
  } else {
    return false;
  }
 }

 static getUserSetupFee(){
  const tokenFromStorage = localStorage.getItem('token');
  if (tokenFromStorage) {
    let decodedToken = jwt_decode(tokenFromStorage);
    return decodedToken['_setup_fee'];
  } else {
    return false;
  }
 }

 static getUserName(){
  const tokenFromStorage = localStorage.getItem('token');
  if (tokenFromStorage) {
    let decodedToken = jwt_decode(tokenFromStorage);
    return decodedToken['_name'];
  } else {
    return false;
  }
 }

 static getUserEmail(){
  const tokenFromStorage = localStorage.getItem('token');
  if (tokenFromStorage) {
    let decodedToken = jwt_decode(tokenFromStorage);
    return decodedToken['_email'];
  } else {
    return false;
  }
 }

}

export default Auth;