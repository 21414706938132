<template >
    <div>
        <div style="height: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
            <span style="font-size: large; color: #0d2c6d;">לוגים</span>
        </div>
        <v-container>

            <v-card style="background-color: white; height: 82vh; overflow-y: auto;">
                <div style="display: flex; padding: 15px; margin-bottom: 20px;">
                    <v-text-field hide-details type="date" v-model="dateLogs" outlined dense
                        style=" max-width: 200px; margin-left: 20px;">
                    </v-text-field>
                    <v-select :items="logsType" v-model="filterLogs" hide-details outlined dense label="סינון לוגים"
                        style="max-width: 200px; margin-left: 20px;">
                    </v-select>
                    <v-text-field label="חיפוש" style="max-width: 300px;" hide-details outlined dense
                        v-model="search"></v-text-field>
                </div>
                <div>
                    <span>תוצאות לוגים: {{ filteredLogs.length }}</span>
                </div>

                <div v-for="log in filteredLogs" 
                    style="padding: 10px; border-bottom: 1px solid #ccc; direction: ltr;">
                    {{ log.date }} {{ log.time }}
                    <span :class="getClass(log.level)">
                        [{{ log.level }}]
                    </span>
                    {{ log.message }}
                </div>
            </v-card>
        </v-container>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
    
<script>

import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Utils from "@/util/utils";
import PlansAddEditDialog from '@/components/admin/plans/dialogs/PlansAddEditDialog'

export default {

    components: {
        SnackBar,
        PlansAddEditDialog
    },
    data: () => ({
        overlay: false,
        editPlanDialog: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        progressShow: false,
        logs: "",
        dateLogs: "",
        filterLogs: "הכל",
        logsType: ["הכל", "שגיאות", "מידע"],
        search: ""
    }),
    computed: {
        filteredLogs() {
            let filtered = this.logs;

            if (this.filterLogs === "שגיאות") {
                filtered = filtered.filter(
                    (log) => log.level === "error:" || log.level === "exception:"
                );
            } else if (this.filterLogs === "מידע") {
                filtered = filtered.filter(
                    (log) => log.level !== "error:" && log.level !== "exception:"
                );
            }

            if (this.search) {
                return filtered.filter(item => {
                    return Object.values(item).some(value => value && value.toString().toLowerCase().includes(this.search.toLowerCase()));
                });
            }

            return filtered;
        },
    },
    methods: {
        async getLogs() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/logs/get_today_logs?date=${this.dateLogs}`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    if (jsonObject) {
                        this.logs = jsonObject.split('\n').map(line => {
                            const parts = line.split(' ');
                            return {
                                date: parts[0],
                                time: parts[1],
                                level: parts[2],
                                message: parts.slice(3).join(' ')
                            };
                        });
                    }
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get logs: " + error, "red");
            }
        },
        getClass(level) {
            return level === 'info:' ? 'level-info' : 'level-error';
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        setDateLogsToCurrentDate() {
            const today = new Date();
            const formattedDate = today.toISOString().substr(0, 10);
            this.dateLogs = formattedDate;
        }
    },
    watch: {
        dateLogs(newVal, oldVal) {
            this.getLogs();
        }
    },
    created() {
        this.setDateLogsToCurrentDate();
    },
}
</script>
<style >
.level-info {
    color: rgb(24, 197, 24);
}

.level-error {
    color: red;
}
</style>
    